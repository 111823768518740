import React from 'react';
import Layout from '../../components/Layout';
import { StaticQuery, graphql, Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import logger from 'utils/logger';

import Header from 'components/Headers/Header';


import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';

class Template extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allPrismicDocument.edges')
    const data = get(this, 'props.data')
    // logger.verbose('data', data);

    var headerProps = {
      height: `70vh`,
      bgColor: `primary-500`,
      bgImage: `https://source.unsplash.com/WLUHO9A_xik/1600x900`,
      parallax: [
        {
          image: 'https://source.unsplash.com/WLUHO9A_xik/1600x900',
          amount: 0.25,
          slowerScrollRate: false,
        },
        // {
        //   // image: 'https://vignette.wikia.nocookie.net/rickandmorty/images/1/19/Pickle_rick_transparent.png',
        //   children: (<img src="https://vignette.wikia.nocookie.net/rickandmorty/images/1/19/Pickle_rick_transparent.png"/>),
        //   amount: -0.25,
        //   slowerScrollRate: true,
        // },
      ],
      /*bgVideo: {
        playsInline: PropTypes.bool,             // play inline on iPhone. avoid triggering native video player
        disableBackgroundCover: PropTypes.bool,  // do not apply cover effect (e.g. disable it for specific screen resolution or aspect ratio)
        style: PropTypes.object,
        className: PropTypes.string,
        containerWidth: PropTypes.number.isRequired,
        containerHeight: PropTypes.number.isRequired,
        src: PropTypes.oneOfType([
          {
            src: 'http://download.blender.org/peach/trailer/trailer_400p.ogg',
            type: 'video/ogg'
          },
          {
            src: 'http://clips.vorwaerts-gmbh.de/VfE_html5.mp4',
            type: 'video/mp4'
          }
        ]).isRequired,
        poster: {'http://il6.picdn.net/shutterstock/videos/3548084/thumb/1.jpg?i10c=img.resize(height:160)'},
        poster: PropTypes.string,
        posterAlt: PropTypes.string,
        horizontalAlign: PropTypes.number,
        verticalAlign: PropTypes.number,
        preload: PropTypes.string,
        muted: PropTypes.bool,   // required to be set to true for auto play on mobile in combination with 'autoPlay' option
        loop: PropTypes.bool,
        volume: PropTypes.number,
        autoPlay: PropTypes.bool,
        extraVideoElementProps: PropTypes.object,
        startTime: PropTypes.number,
        tabIndex: PropTypes.number,
        shouldComponentUpdate: PropTypes.bool,
        onReady: PropTypes.func, // passes back `duration`
        onPlay: PropTypes.func,
        onPause: PropTypes.func,
        onMute: PropTypes.func,
        onUnmute: PropTypes.func,
        onTimeUpdate: PropTypes.func, // passes back `currentTime`, `progress` and `duration`
        onEnd: PropTypes.func,
        onClick: PropTypes.func,
        onKeyPress: PropTypes.func
      },*/
      ui: 120,
    }
    return (
      <Layout>
        <Fluid>
          <SEO title={`Career`} />
          <Header {... headerProps}>
            <Offset>
              <h1>Career</h1>
              <p className="lead">Lorem ipsum doler set amit.</p>
            </Offset>
          </Header>
          <article>
            <Offset>
              <p>article...</p>
            </Offset>
          </article>
        </Fluid>
      </Layout>
    );
  }
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Helmet title={`${`Career`}`} />
        <Template data={data}>
          {children}
        </Template>
      </>
    )}
  />
);

